.section.video-section {
    @include backgroundImage('images/video-bg.webp');
    background-size: cover;
    background-attachment: fixed;
    .video {
        padding: 25px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        

        .video-border {
            position: relative;
            width: 80vw;
            padding-top: 46.47vw;
            
            @media (min-width: 1350px) {
                width: 1140px;
                padding-top: 660px;
            }
            
            iframe {
                background: black;
                position: absolute;
                top: 3%;
                left: 2%;
                width: 96%;
                height: 94%;
                z-index: 1;
            }
            

            &::after {
                content: " ";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 2;
                pointer-events: none;
                @include backgroundImage('images/video-border.png');
                background-size: contain;
                background-position: center;
            }
        }

   
    }
}