.section.header {
    margin-bottom: 75px;
    max-width: 100vw;
    overflow: hidden;
    position: relative;
    z-index: 1000;

    @include media-tablet-down {
        margin-bottom: 45px;
    }
}

.section.header > .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-grow: 1;
    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    z-index: 1700;
    @include backgroundImage('images/bg-header.png');
    background-size: 100px 85px;
    background-color: #3f2f27;
    background-repeat: repeat-x;
    background-position-y: bottom;
    box-shadow: 0 4px 0 4px rgba($black, 0.15);
    
    nav {
        max-width: 1020px;        
        width: calc(100% - 30px);
        margin: 2px auto 12px;
        padding: 0;
        color: $white;

        
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 75px;
        

        @include media-tablet-down {
            justify-content: space-between;
            height: 45px;
        }
    }
    
    .logo {
        display: block;
        margin-right: 23px;
        transition: .5s ease all;
        background: transparent;
        border: 0;
        cursor: pointer;
        img {
            width: auto;
            height: 75px;
            transition: .5s ease all;

            &:hover {
                transform: scale(1.1);
            }
        }

        @include media-tablet-down {
            margin-left: 0px;

            img {
                height: 45px;
            }
        }
    }

    .navlink {
        background: transparent;
        border: 0;
        font-size: 18px;
        color: $white;
        text-transform: uppercase;
        font-weight: normal;
        text-decoration: none;
        display: block;
        margin: 0px 23px;
        cursor: pointer;
        position: relative;
        transition: .5s ease;
        text-shadow: 1px 1px 0px $black, 2px 2px 0px $black;
        text-transform: none;
        user-select: none;

        @include media-desktop-down {
            margin: 0 10px;
        }
        @include media-tablet-down {
            display: none;
        }

        &::after {
            display: block;
            height: 3px;
            width: 0;
            position: absolute;
            left: 50%;
            bottom: -8px;
            content: " ";
            background: $white;
            transform: translateX(-50%);
            transition: .5s ease;
            @include media-tablet-down {
                display: none;
            }
        }


        &:hover, &.active {
            color: $highlight;

            &::after {
                width: 100%;
                background-color: $highlight;
            }
        }


    }



    .hamburger-button {
        @include backgroundImage('/images/hamburger-icon.png');
        width: 35px;
        height: 35px;
        border: 0;
        background-size: contain;
        background-color: transparent;
        background-position: center;
        margin-right: 10px;
        padding: 0;
        cursor: pointer;
        display: none;
        position: absolute;
        top: 4px;
        right: 0;
        @include media-tablet-down {
            display: block;
        }
    }
}

.section.header.opened > .wrapper {
    @include media-tablet-down {
        height: auto;
        

        nav {
            display: block;
            height: auto;
            
        }

        .logo {
            width: 100%;
            margin-bottom: 10px;
            margin-top: 10px;
            text-align: center;
            img {
                height: 200px;
            }
        }

        .navlink {
            width: 100%;
            display: block;
            padding: 20px 10px;
            font-size: 20px;
            border-top: 1px solid rgba($white, 0.2);
            margin: 0;
            text-transform: none;
        }

        .socials {
            display: flex;
            padding-bottom: 10px;
            flex-wrap: wrap;
            margin: 20px 10px 0;
            width: calc(100% - 20px);
            .social {
                width: 35px;
                height: 35px;
                margin: 8px;
            }
        }
    }
}