.section.home-news {
    @include backgroundImage('images/bg-blue-circles.webp');
    display: block;
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
    z-index: 0;
    position: relative;
    padding-top: 30px;
    padding-bottom: 50px;
    margin-bottom: 0;
 
    @include media-tablet-down() {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 0;
    }

    .wrapper {
        @include media-tablet-down() {
            width: 100%;
            max-width: 100%;
            overflow: hidden;
        }
            
    }

    h1 {
        
        display:flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        transition: .2s ease-in;
        text-transform: uppercase;
        color: $highlight;
        font-size: 36px;
        font-weight: bold;
        text-shadow: 1px 1px 0px #000000, 2px 2px 0px #000000, 3px 3px 0px #000000;
        margin-bottom: 30px;
    }

    .news-border {
        padding: 0 20px 20px;
        height: 245px;
        overflow: hidden;
        position: relative;
        @include media-desktop-down() {
            height: calc(30vw - 20px);
        }
        @include media-tablet-down() {
            height: calc(185vw + 50px);
        }
        &.opened {
            height: auto;
        }
    }

    .news-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        .news-box {
            width: calc(33.333% - 20px);
            padding: 0;
            margin: 10px;
            height: 245px;
            transition: .2s ease-in;
            background-color: #b4d1cd;

            @include media-desktop-down() {
                width: calc(50% - 20px);
                height: 30vw;
            }
            @include media-tablet-down() {
                width: calc(100% - 20px);
                height: 60vw;
                margin-bottom: 5vw;
            }
            img {
                width: 100%;
                height: auto;
            }
            
            .box-content {
                display: block;
                position: relative;     
                padding: 5px;
            }
            
            h2 {
                color: $black;
                font-size: 20px;
                line-height: 120%;
                display: block;
                max-height: 51px;
                overflow: hidden;

                @include media-tablet-down() {
                    font-size: 15px;
                }
            }

            &:hover {
                background-color: $highlight;
                box-shadow: 0 0 0 10px $highlight;
                .more-button:after {
                    opacity: 1;
                    transform: scale(1.0);
                }
            }
        }
    }

    .show-more-button {
        margin: 20px auto 0;
        display: block;
        background-color: #80231f;
        border: 0;
        font-size: 24px;
        color: $highlight;
        text-transform: uppercase;
        padding: 15px 20px;
        transition: .2s ease-in;
        cursor: pointer;
        text-shadow: 1px 1px 0px #000000, 2px 2px 0px #000000;
        font-weight: bold;

        &:hover {
            box-shadow: 0 0 0 5px $highlight;
            background-color: #ca422a;
        }

        @include media-tablet-down() {
            margin-top: 0;
            width: 100%;
            margin-bottom: 0;
            background-size: contain;
            background-position: right center;
            
        }
    }
}
