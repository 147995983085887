@keyframes pan {
    0% {transform: translateX(3%) scale(1.1);}
    35% {transform: translateX(-1%) translateY(5%) scale(1.5);}
    66% {transform: translateX(-2%) translateY(2%) scale(1.15);}
    66% {transform: translateX(-1%) translateY(1%) scale(1.12);}
    100% {transform: translateX(3%) scale(1.1);}
}

@keyframes blink {
    0% {
        opacity: 0.0;
      }
      50% {
        opacity: 0.1;
      }
      53% {
        opacity: 0.0;
      }
      54% {
        opacity: 0.3;
      }
      57% {
        opacity: 0.0;
      }
      58% {
        opacity: 0.3;
      }
      100% {
        opacity: 0.0;
      }
  }


.section.hero {
  width: 100%;
  height: auto;
  @include backgroundImage("images/features-bg-blur.webp");
  background-size: cover;  
  background-position: center;

  .inner-section {
    width: 100%;
    position: relative;
    z-index: 1;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    height: calc(70vh - 100px);
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    @include backgroundImage("images/features-bg-blur.webp");


    @include media-desktop-down {
      height: 50vw;
    }


    &::before {
        display: block;
        content: " ";
        @include backgroundImage('images/hero-big.webp');
        position: absolute;
        top: 0;
        left: 0;
        width: 150%; /* Set the width to be twice the viewport width */
        height: 100%;
        animation: pan 60s infinite linear; /* Adjust the duration (20s in this example) as needed */
        background-size: cover;
    }

    .wrapper {
        width: 100%;
        //max-width: 1420px;
        
        .logo-image, .logo-image-light {
            position: absolute;
            left: 10%;
            top: 0;
            
              height: 75%;
              width: auto;
            
        }

        .logo-image {
            z-index: 1;
        }
        
        .logo-image-light {
            z-index: 2;
            animation: blink 4s infinite;
            pointer-events: none;
        }

    }
  }
}