.section.footer {
    margin-top: 0;
    max-width: 100vw;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #402f27;
    border-top: 7px solid #ec673e;
    box-shadow:  0 0 0 2px $black, 0 0 0 10px rgba($black, 0.2);    
    text-shadow: 1px 1px 0px #000000, 2px 2px 0px #000000;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $black;
        display: block;
        content: " ";
    }

    @include media-tablet-down {
        padding: 20px 0;
        margin-top: 0;
    }

    > .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-grow: 1;
    }
    
    
    footer {
        margin: 0;
        padding: 0;
        color: $white;

        z-index: 170;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 160px;

        @include media-tablet-down {
            flex-wrap: wrap;
            height: auto;
            padding: 10px 0;
        }

    }
    
    .logo {
        display: block;
        margin-right: 75px;

        @include media-tablet-down {
            margin-right: 0;
        }
        img {
            width: auto;
            height: 100px;
            transition: .5s ease all;
            filter: drop-shadow(2.5px 2.5px 0 $black);
            
            &:hover {
                transform: scale(1.1);
            }
        }

    }


    .text {
        width: 485px;
        max-width: calc(100vw - 596px);
        font-size: 21px;
        @include media-tablet-down {
            width: 100%;
            max-width: 100%;
            text-align: center;
            padding: 40px 0;
        }
        .navlink {
            color: $highlight;
            text-decoration: none;
            display: inline-block;
            padding-top: 5px;
        }
    }



}
