//@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Pangolin&family=VT323&display=swap');

$black:      #000000;
$white:      #ffffff;
$bgcolor:    #121214;
$grey:       #232323;
$highlight:  #eead53;
$darkyellow: #977a17;

$assets: '../';

@font-face {
    font-family: 'Bazar';
    src: url($assets + 'fonts/bazar_500-webfont.woff2') format('woff2'),
    url($assets + 'fonts/bazar_500-webfont.woff') format('woff'),
    url($assets + 'fonts/bazar_500-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Noir';
    src: url($assets + 'fonts/Medium.woff2') format('woff2'),
    url($assets + 'fonts/Medium.woff') format('woff'),
    url($assets + 'fonts/Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


