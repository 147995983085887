.section.key-features { 
    display: block;
    @include backgroundImage("images/features-bg-blur.webp");
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;

    .wrapper {
        max-width: 1920px;
        width: 100%;
        display: flex;
        justify-content: center;
        box-shadow: 0 2px 0 2px rgba($black, 1);
        

        @include media-phone-only() {
            height: 100vw;   
        }
    }

	.content { 
        @include backgroundImage("images/features-bg.webp");
        background-size: cover;
        background-position: center center;
        font-size: 1vw;
        height: 44em;
        width: 100%;
        padding-top: 1em;
        

        @media (min-width: 1600px) {
            font-size: 18px;
        }

        @include media-phone-only() {
            height: 100vw;   
            font-size: 2.5vw;
        }

		.feature-list { 
            display: block;
            text-align: center;
            
			.feature { 
                position: relative;
                margin-bottom: 0.8em;
                box-sizing: border-box;
                padding: 0.5em 0.8em;
                font-weight: normal;
                width: auto;
                white-space: nowrap;
                text-align: center;
                display: inline-block;
                align-items: center;
                justify-content: center;
                color: $highlight;
                border: 0.3em solid #ec673e;
                background: #403028;
                border-radius: 3vw;
                text-shadow: 1px 1px 0px #000000, 2px 2px 0px rgba(#000000, 0.5);
                box-shadow: 0 0 0 1px $black, inset 0 0 0 1px $black;

                &:after {
                    @include backgroundImage('images/thinkdirection.webp');
                    width: 1.2em;
                    height: 1.2em;
                    display: block;
                    content: " ";
                    position: absolute;
                    bottom: -1.41em;
                    background-size: contain;
                    background-position: center top;
                }

				p { 
                    font-size: 1em;
                    line-height: 1.2em;
				}

                &.f1 {
                    margin-left: -8em;
                    &:after {
                        left: 2em;
                    }
                }
                &.f2 {
                    margin-left: 12em;
                    &:after {
                        right: 2em;
                        transform: scaleX(-1);
                    }
                }
                &.f3 {
                    margin-left: -10em;
                    &:after {
                        left: 1em;
                        transform: scaleX(-1);
                    }
                }
                &.f4 {
                    margin-top: -1em;
                    margin-bottom: -0.5em;
                    margin-left: 5em;
                    position: relative;
                    top: -1em;
                    &:after {
                        right: 2em;
                    }
                }
                &.f5 {
                    margin-top: -0.5em;
                    margin-left: -20em;
                    &:after {
                        left: 20%;
                    }
                }
                &.f6 {
                    margin-top: -1em;
                    margin-left: 20em;
                    position: relative;
                    top: -1em;
                    &:after {
                        right: 50%;
                        transform: scaleX(-1);
                    }

                }
			}
		}


	}
}