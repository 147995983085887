body {
    background-color: $black;
    color: $white;
    margin: 0;
    padding: 0;
    font-family: 'Oswald', sans-serif;
   
    background-attachment: fixed;
    background-size: 100vw;
    background-repeat: repeat-y;
    background-position: center;
}

* {
    font-family: 'Oswald', sans-serif;
}


.section {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    flex-wrap: wrap;

    .wrapper {
        width: 1074px;
        max-width: calc(100% - 60px);
        margin: 0px auto;
        display: block;
    }
}


.separator-title {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: $highlight;
    height: 78px;
    @include backgroundImage('/images/bg-separator.png');
    background-repeat: repeat-x;
    background-size: contain;
    box-shadow: 0 4px 0 4px rgba($black, 0.15); 
    font-size: 36px;
    text-shadow: 1px 1px 0px $black, 2px 2px 0px $black;
    position: relative;
    z-index: 3;
    @include media-phone-only() {
        font-size: 8vw;
        height: 17vw;
    }
}

.socials {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 14px;
    @include media-tablet-down {
        display: none;
        width: 100%;
    }
    .social {
        display: block;
        width: 30px;
        height: 30px;
        margin-left: 10px;
        background-size: contain;
        cursor: pointer;
        transition: .5s ease;
        border-radius: 50%;
        transition: .5s ease;
        background-color: $black;
        border: 1px solid $black;
        box-shadow: 0 0 0 1px rgba($black, 0.5);
        transform: scale(1.1);
        image-rendering: smooth;

        &.steam {@include backgroundImage('/images/social-steam.png')}
        &.instagram {@include backgroundImage('/images/social-instagram.png')}
        &.x {@include backgroundImage('/images/social-x.png')}
        &.youtube {@include backgroundImage('/images/social-youtube.png')}
        &.facebook {@include backgroundImage('/images/social-facebook.png')}
        &.discord {@include backgroundImage('/images/social-discord.png')}

        &:hover {
            background-color: $highlight;
            border-color: $highlight;
            box-shadow: 0 0 0 1px $highlight;
            transform: scale(1.2);
        }


    }
}
